<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
const route = useRoute()

watch(
  () => route,
  async () => {
    const counterpartyRoutes = ['entity-accounts-contacts-named', 'entity-accounts-contacts-unnamed']
    if (counterpartyRoutes.includes(String(route.name))) {
      console.log('contacts')
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped></style>
